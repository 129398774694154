import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarTriggerService {
  public closeSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public triggerCloseSidebar(): void {
    this.closeSidebar.next(true);
  }
}
